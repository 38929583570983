import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components';
import { 
  type_body, 
  margin, 
  baseColor, 
  greyColor, 
  blackColor, 
  grid,
  colorTransition 
} from '../styles/helpers'
import { navigate } from "gatsby"

const NotFoundText = styled.div`
  position: absolute;
  display: flex;
  ${type_body}
  top: 50%;
  left: 50%;
  transform: translatex(-50%) translateY(-50%);
`

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/')
  }, [])

  return (
    <>
      <SEO title="404: Not found" />
      <NotFoundText>The requested page was not found</NotFoundText>
    </>
  )
}

export default NotFoundPage
